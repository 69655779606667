.login {
    display: flex;
    width: 100%;
}

.form-container {
    background-color:#3786C7;
    width: 30%;
    height: 100vh;
    color: white;
    display: flex;
    align-items: center;
}

.banner-container {
    width: 100%;
    background: url(../../public/iStock-1088239910.jpg);
    background-size: 100%;
    color: white;
    align-items: center;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.banner-header-container {
    background: rgba(255,255,255,0.6);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center
}

.forgot-password {
    display: flex;
    justify-content: space-between;
}

.username-password div {
    margin-bottom: 5px;
}

.login-form {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.banner-subtext {
    color: #575B6C;
    margin-top: -5px;
    font-size: 50px;
}

.banner-subtext-two {
    color: #575B6C;
    margin-top: -25px;
    font-size: 24px;
}

.need-account {
    display: flex;
    align-items: center;
}